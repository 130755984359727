import React, { useRef, useContext, useState } from 'react'
import { HomeContext } from '../pages/Home'
import { UPDATE_USER_PORTFOLIO } from '../contexts/HomeActionType'
import { callDxertApi } from '../utils/DxertApiUtil'
import { SEARCH_ASSET, GET_PORTFOLIO, UPDATE_PORTFOLIO } from '../utils/DxertApiType'

function PortfolioDashboardSearch() {
    const { homeContext, homeDispatch }  = useContext(HomeContext)
    const [assets, setAssets] = useState([])
    const [disableAddAsset, setDisableAddAsset] = useState(false)
    const [disableSearchButton, setDisableSearchButton] = useState(false)
    const tickerInput = useRef(null)

    const searchButtonClick = () => {
        setDisableSearchButton(true)
        const sendDate = {
            keyword: tickerInput.current.value,
        }
        callDxertApi(SEARCH_ASSET, sendDate, (data) => {
            setAssets(data.assets)
            setDisableSearchButton(false)
        })
    }

    const addButtonClick = (ticker) => {
        setDisableAddAsset(true)
        const sendDate = {
            ticker: ticker,
            type: 'ADD'
        }
        callDxertApi(UPDATE_PORTFOLIO, sendDate, (data) => {
            if (data.success) {
                callDxertApi(GET_PORTFOLIO, {}, (data) => {
                    homeDispatch({type: UPDATE_USER_PORTFOLIO, portfolio: data.portfolio})
                    setDisableAddAsset(false)
                })
            }
        })
    }

    const assetAlreadyAdded = (ticker) => {
        if (homeContext.portfolio) {
            for (const asset of homeContext.portfolio) {
                if (asset.ticker === ticker) {
                    return true
                }
            }
        }
        return false
    }

    return (
        <>
            <div className='search_bar'>
                <input ref={tickerInput} type='text' placeholder='Search new ticker..'/>
                <button className='search_button' onClick={searchButtonClick} disabled={disableSearchButton}></button>
            </div>
            <div className='portfolio_dashboard_header_search_result'>
            {
                assets.map((asset) => {
                    return (
                    <div key='asset.id.id' className='portfolio_dashboard_header_search_result_item'>
                        <div>Ticker: {asset.ticker} | Name: {asset.name}</div>
                        {
                            !assetAlreadyAdded(asset.ticker) &&
                            <button className='add_button' onClick={() => (addButtonClick(asset.ticker))} disabled={disableAddAsset}></button>
                        }
                    </div>
                    )
                })
            }
            </div>
        </>
    )
}

export default PortfolioDashboardSearch