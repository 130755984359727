import React, { useEffect, useReducer, createContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { callDxertApi } from '../utils/DxertApiUtil'
import { GET_USER_INFO, GET_PORTFOLIO } from '../utils/DxertApiType'
import { UPDATE_USER_ASSET, UPDATE_USER_INFO } from '../contexts/AssetActionType'
import { AssetReducer, DefaultAssetContext } from '../contexts/AssetContext'
import AssetDashboardDetail from '../components/AssetDashboardDetail'
import AssetDashboardAction from '../components/AssetDashboardAction'
import AssetDashboardJournal from '../components/AssetDashboardJournal'

export const AssetContext = createContext()

function Asset(props) {
    const ticker = props.match.params.ticker
    const [assetContext, assetDispatch] = useReducer(AssetReducer, DefaultAssetContext)

    const [ready, setReady] = useState(false)
    const [redirect, setRedirect] = useState(true)

    useEffect(()=>{
        callDxertApi(GET_USER_INFO, {}, (data) => {assetDispatch({type: UPDATE_USER_INFO, userInfo: data.user})})

        callDxertApi(GET_PORTFOLIO, {}, (data) => {
            const portfolio = data.portfolio
            for (const asset of portfolio) {
                if (ticker.toUpperCase() === asset.ticker.toUpperCase()) {
                    setRedirect(false)
                    assetDispatch({type: UPDATE_USER_ASSET, asset: asset})
                    break
                }
            }
            setReady(true)
        })
    },[ticker])

    return (
        <>
        {
            ready &&
            <>
            {
                redirect ? 
                <Redirect
                    to={{
                        pathname: "/home/"
                    }}
                /> :
                <AssetContext.Provider value={{assetContext, assetDispatch}}>
                    <div className='asset_dashboard'>
                        <div className='asset_dashboard_detail'>
                            <AssetDashboardDetail />
                        </div>
                        <div className='asset_dashboard_action_and_journal'>
                            <div className='asset_dashboard_action'>
                                <AssetDashboardAction />
                            </div>
                            <div className='asset_dashboard_journal'>
                                <AssetDashboardJournal />
                            </div>
                        </div>
                    </div>
                </AssetContext.Provider>
            }
            </>
        }
        </>
    )
}

export default Asset