import { getJwtToken } from './CognitoUtil'
import axios from 'axios';

export const callDxertApi = async (apiType, sendData, onSuccess, onError = defaultOnError, onFailure = defaultOnFailure) => {

    const time = new Date().toLocaleString
    const loadScreen = '<div name="'+ time +'" class="load_screen">Loading...</div>'
    document.body.insertAdjacentHTML('beforeend',loadScreen)

    const { url, needAuth } = apiType

    const request = {
        method: 'post',
        url: url,
        data: sendData,
        headers: {'Content-Type': 'application/json'}
    }

    if (needAuth) {
        const jwtToken = await getJwtToken()

        if (!jwtToken) {
            window.location.href="/signout"
            return
        }

        if (needAuth) {
            request.headers['Authorization'] = "Bearer " + jwtToken
        }
    }

    const response = await axios(request)

    if (isResponseOK(response)) {
        if (isResponsSuccess(response)) {
            onSuccess(response.data)
        } else {
            onError(response.data.errors)
        }
    } else {
        onFailure()
    }

    const loadScreens = document.getElementsByName(time)
    while (loadScreens.length > 0) {
        document.body.removeChild(loadScreens[0])
    }
}

const defaultOnError = (errors) => {
    alert(errors[0].code)
}

const defaultOnFailure = () => {
    alert('Failed!')
}

const isResponseOK = (response) => {
    return response.status === 200
 };
 
const isResponsSuccess = (response) => {
    return response.data.errors === undefined || response.data.errors.length === 0
 }
 