import React, { createContext, useReducer, useEffect } from 'react'
import { DefaultHomeContext, HomeReducer } from '../contexts/HomeContext'
import { callDxertApi } from '../utils/DxertApiUtil'
import { GET_USER_INFO, GET_PORTFOLIO} from '../utils/DxertApiType'
import { UPDATE_USER_INFO, UPDATE_USER_PORTFOLIO } from '../contexts/HomeActionType'
import PortfolioDashboard from '../components/PortfolioDashboard'
import JournalDashboard from '../components/JournalDashboard'

export const HomeContext = createContext()

function Home() {
    const [homeContext, homeDispatch] = useReducer(HomeReducer, DefaultHomeContext)

    useEffect(()=>{
        callDxertApi(GET_USER_INFO, {}, (data) => {homeDispatch({type: UPDATE_USER_INFO, userInfo: data.user})})
        callDxertApi(GET_PORTFOLIO, {}, (data) => {homeDispatch({type: UPDATE_USER_PORTFOLIO, portfolio: data.portfolio})})
    },[])

    return (
        <HomeContext.Provider value={{homeContext, homeDispatch}}>
            <div className='dashboard'>
                <div className='portfolio_dashboard'>
                    <PortfolioDashboard />
                </div>
                <div className='journal_dashboard'>
                    <JournalDashboard />
                </div>
            </div>
        </HomeContext.Provider>
    )
}

export default Home