import { UPDATE_USER_INFO, UPDATE_USER_PORTFOLIO, UPDATE_PORTOLIO_FILTER, UPDATE_USER_PROFILE } from './HomeActionType'

export const DefaultHomeContext = {
    userInfo: {},
    portfolio: null
}

export const HomeReducer = (state, action) => {
    switch(action.type) {
        case UPDATE_USER_INFO:
            return updateUserInfo(state, action)
        case UPDATE_USER_PORTFOLIO:
            return updateUserPortfolio(state, action)
        case UPDATE_PORTOLIO_FILTER:
            return updatePortfolioFilter(state, action)
        case UPDATE_USER_PROFILE:
            return updateUserProfile(state, action)
        default:
            return state
    }
}

function updateUserInfo(state, action) {
    let newState = {...state}
    newState.userInfo = action.userInfo
    return newState
}

function updateUserPortfolio(state, action) {
    let newState = {...state}
    newState.portfolio = action.portfolio
    return newState
}

function updatePortfolioFilter(state, action) {
    let newState = {...state}
    newState.portfolioFilter = action.portfolioFilter
    return newState
}

function updateUserProfile(state, action) {
    let newState = {...state}
    newState.userInfo.profile = action.profile
    return newState
}