import React, { useContext, useMemo, useState, useRef } from 'react'
import { GET_ACTION, ADD_ACTION, GET_USER_INFO } from '../utils/DxertApiType'
import { UPDATE_USER_ACTIONS, UPDATE_USER_INFO } from '../contexts/AssetActionType'
import { callDxertApi } from '../utils/DxertApiUtil'
import { AssetContext } from '../pages/Asset'

function AssetDashboardAction() {
    const { assetContext, assetDispatch }  = useContext(AssetContext)
    const [showAddAction, setShowAddAction] = useState(false)
    const addActionForm = useRef(null)
    const asset = assetContext.asset
    const actions = assetContext.actions
    const [disableSubmit, setDisableSubmit] = useState(false)

    const timeFrameUnits = ['day', 'week', 'month', 'year']
    const confidenceLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    
    const fetchActions = useMemo(() => {
        callDxertApi(GET_ACTION, {tickers: [asset.ticker]}, (data) => {assetDispatch({type: UPDATE_USER_ACTIONS, actions: data.actions})})
    }, [asset.ticker, assetDispatch])

    const generateActionList = () => {
        if (!actions) {
            return <></>
        }
        const result = []
        for (const action of actions) {
            result.push(
                <div className='asset_action_board_List_item' key={'assetActionListItem-' + action.timestamp.timestamp}>
                    <div>Action Type: {action.type}</div>
                    <div>Price: {action.price}</div>
                    <div>Qty: {action.position} PT: {action.pt}</div>
                    <div>Time Frame: {action.timeFrame.value}{action.timeFrame.unit}</div>
                    <div>Confidence: {action.confidence}</div>
                    <div>TimeStamp: {new Date(action.timestamp.timestamp).toLocaleString('en')}</div>
                </div>
            )
        }
        return result
    }

    const addActionButtonClick = () => {
        setDisableSubmit(true)
        const sendData = {
            action: {
                ticker: asset.ticker,
                type: addActionForm.current.addActionType.value,
                position: addActionForm.current.addActionQty.value,
                pt: addActionForm.current.addActionPt.value,
                price: addActionForm.current.addActionPrice.value,
                timeFrame: {
                    unit: addActionForm.current.addActionTimeFrameUnit.value,
                    value: addActionForm.current.addActionConfidence.value
                },
                confidence: addActionForm.current.addActionConfidence.value
            }
        }

        callDxertApi(ADD_ACTION, sendData, (data) => {
            callDxertApi(GET_ACTION, {tickers: [asset.ticker]}, (data) => {assetDispatch({type: UPDATE_USER_ACTIONS, actions: data.actions})})
            callDxertApi(GET_USER_INFO, {}, (data) => {assetDispatch({type: UPDATE_USER_INFO, userInfo: data.user})})
            setDisableSubmit(false)
        })
    }

    return (
        <>
        Actions
        {
            asset &&
            fetchActions
        }
        {
            actions &&
            <div className='asset_action_board'>
                <button className='asset_add_action_button' onClick={() => {setShowAddAction(!showAddAction)}}></button>
                {
                    showAddAction &&
                    <div className='asset_add_action_area'>
                        <form ref={addActionForm}>
                            <div>
                                <input type='radio' value='BUY' name='addActionType' /> Buy
                                <input type='radio' value='SELL' name='addActionType' /> Sell
                            </div>
                            <div>
                                Qty: <input type='number' min='1' name='addActionQty' />
                                PT: <input type='number' name='addActionPt' />
                            </div>
                            <div>
                                Time frame: 
                                <input type='number' min='1' name='addActionTimeFrameValue' />
                                <select name='addActionTimeFrameUnit'>
                                    {
                                        timeFrameUnits.map((value, index) => {
                                            return <option key={'timeFrameUnits-' + index} value={value}>{value}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                Confidence:
                                <select  name='addActionConfidence'>
                                    {
                                        confidenceLevel.map((value, index) => {
                                            return <option key={'confidenceLevel-' + index} value={value}>{value}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <input type='hidden' value='100.00' name='addActionPrice'/>
                        </form>
                        <button onClick={addActionButtonClick} disabled={disableSubmit}>Sumbit</button>
                    </div>
                }
                {
                    generateActionList()
                }
            </div>
        }
        </>
    )
}

export default AssetDashboardAction