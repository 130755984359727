import React, { useState, useEffect } from 'react'
import { callDxertApi } from '../utils/DxertApiUtil'
import { GET_JOURNAL } from '../utils/DxertApiType'
import { Link } from 'react-router-dom'

function Journals() {
    const [journals, setJournals] = useState([])

    useEffect(()=>{
        const sendData = {
            tickers: [],
            journalIds: []
        }
        callDxertApi(GET_JOURNAL, sendData, (data) => {setJournals(data.journals)})
    },[])

    const generateJournalList = () => {
        const result = []
        journals.map((journal) => {
            result.push(
                <div key={journal.id.id} className='journals_page_list_item'>
                    <Link to={'/journal/' + journal.id.id}>{journal.title}</Link>
                </div>
            )
            return <></>
        })
        return result
    }
    
    return (
        <>
            <div className='journals_page_header'>
                <Link to='/newJournal/'>
                    <button className='add_button'></button>  
                </Link>
            </div>
            {
                journals.length > 0 &&
                <div className='journals_page_list'>
                    {
                        generateJournalList()
                    }
                </div>
            }
        </>
    )
}

export default Journals