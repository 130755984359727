import React, { useContext } from 'react'
import { HomeContext } from '../pages/Home'

function PortfolioDashboardSummary() {
    const { homeContext }  = useContext(HomeContext)
    return (
        <>
        {
            homeContext.portfolio &&
            homeContext.portfolio.map((asset, index) => {
                return (
                    <div key={index}>
                    {
                        asset.status === 'ACTIVE' && 
                        <>
                        {asset.ticker} : {asset.position}
                        </>
                    }
                    </div>
                )
            })
        }
        </>
    )
}

export default PortfolioDashboardSummary