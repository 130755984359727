import React, { useEffect, useState, useRef } from 'react'
import { callDxertApi } from '../utils/DxertApiUtil'
import { GET_JOURNAL, UPDATE_JOURNAL } from '../utils/DxertApiType'

function Journal(props) {

    const journalId = props.match.params.journalId
    const [journal, setJournal] = useState(null)
    const [edit, setEdit] = useState(false)
    const editJournalForm = useRef(null)

    useEffect(()=>{
        const sendData = {
            tickers: [],
            journalIds: [journalId]
        }
        callDxertApi(GET_JOURNAL, sendData, (data) => {
            data.journals.length > 0 &&
            setJournal(data.journals[0])
        })
    },[journalId])

    const submitButtonClick = () => {
        const updatedJournal = {...journal}

        updatedJournal.title = editJournalForm.current.title.value
        updatedJournal.content = editJournalForm.current.content.value
        if (journal.ticker) {
            updatedJournal.ticker = journal.ticker
        }

        callDxertApi(UPDATE_JOURNAL, {journal: updatedJournal}, (data) => {
            const sendData = {
                tickers: [],
                journalIds: [journalId]
            }
            callDxertApi(GET_JOURNAL, sendData, (data) => {
                data.journals.length > 0 &&
                setJournal(data.journals[0])
                setEdit(false)
            })
        })
    }

    return (
        <>
        {
            journal && 
            <div className='add_journal_wrapper'>
            {
                journal.ticker &&
                <div>Ticker: {journal.ticker}</div>
            }
                <div><button className='edit_button' onClick={() => {setEdit(true)}}></button></div>
            {
                edit ?
                <>
                    <form ref={editJournalForm}>
                    <div>
                        Title: <input className='jounal_title_input' type='text' name='title' defaultValue={journal.title}/>
                    </div>
                    <div>
                        <textarea className='jounal_content_input' name='content' rows='30' defaultValue={journal.content}/>
                    </div>
                    </form>
                    <button onClick={submitButtonClick}>Submit</button>
                </>
                :
                <>
                    <div className='journal_title'>{journal.title}</div>
                    <div>{new Date(journal.timestamp.timestamp).toLocaleString('en')}</div>
                    <div className='journal_content'>{journal.content}</div>

                </>
            }
            </div>
        }
        </>
    )
}

export default Journal