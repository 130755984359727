import React, { useContext, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { callDxertApi } from '../utils/DxertApiUtil'
import { GET_USER_INFO, ADD_ASSET_CATEGORY, ADD_ASSET_CUSTOMER_FIELD, UPDATE_PROFILE } from '../utils/DxertApiType'
import { HomeContext } from '../pages/Home'
import { UPDATE_USER_INFO, UPDATE_USER_PROFILE } from '../contexts/HomeActionType'

function PortfolioDashboardList() {
    const { homeContext, homeDispatch }  = useContext(HomeContext)
    const [showEditCategory, setShowEditCategory] = useState(false)
    const [disableAddCategory, setDisableAddCategory] = useState(false)
    const addCategoryInput = useRef(null)
    const addCustomerFieldInput = useRef(null)
    
    const portfolioFilter = homeContext.portfolioFilter

    const shouldShowByTagFilter = (filters, tags) => {
        if (filters && portfolioFilter.length > 0) {
            if (tags && tags.length > 0) {
                return filters.some(r => tags.indexOf(r) >= 0)
            } else {
                return false
            }
        } else {
            return true
        }
    }

    const generateProtfolioListGroup= (assets, cat='') => {

        if (!assets || assets.length === 0 || !homeContext.userInfo.profile) {
            return <></>
        }
        
        const customerFields = homeContext.userInfo.customerFields
        const hiddenAssetCustomerFields = homeContext.userInfo.profile.hiddenAssetCustomerFields

        const fileds = []
        if (customerFields && customerFields.length > 0)
        for (const cf of customerFields) {
            if (!hiddenAssetCustomerFields || !hiddenAssetCustomerFields.includes(cf)) {
                fileds.push(cf)
            }
        }

        return (
            <table className='portfolio_dashboard_table'>
                <tbody>
                    <tr>
                        <th>Ticker</th>
                        <th>Status</th>
                        <th>position</th>
                        {
                            fileds.map((field, index) => {
                                return <th key={cat + 'tableHeader' + index}>{field}</th>
                            })
                        }
                    </tr>
                    {
                        assets.map((asset, index) => {
                            if (!shouldShowByTagFilter(portfolioFilter, asset.tags)) {
                                return <React.Fragment key={cat + asset + 'tableRow' + index}></React.Fragment>
                            }
                            return (
                                <tr key={cat + asset + 'tableRow' + index}>
                                    <th><Link to={'/asset/' + asset.ticker}>{asset.ticker}</Link></th>
                                    <th>{asset.status}</th>
                                    <th>{asset.position}</th>
                                    {
                                        fileds.map((field, index) => {
                                            return <th key={cat + asset + field + 'tableCell' + index}>
                                                {
                                                    asset.customerFields &&
                                                    asset.customerFields.map((customerField, index) => {
                                                        if (customerField.name === field) {
                                                            return <React.Fragment key={cat + asset + field + customerField.value + 'tableCellValue' + index}>{customerField.value}</React.Fragment>
                                                        }
                                                        return <React.Fragment key={cat + asset + field + customerField.value + 'tableCellValue' + index}></React.Fragment>
                                                    })
                                                }
                                            </th>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }

    const addCategoryButtonClick = () => {
        setDisableAddCategory(true)
        const sendData = {
            category: addCategoryInput.current.value
        }
        callDxertApi(ADD_ASSET_CATEGORY, sendData, (data) => {
            if (data.success) {
                callDxertApi(GET_USER_INFO, {},
                    (data) => {
                        homeDispatch({type: UPDATE_USER_INFO, userInfo: data.user})
                        addCategoryInput.current.value = ''
                        setDisableAddCategory(false)
                })
            }
        })
    }

    const addCustomerFieldButtonClick = () => {
        setDisableAddCategory(true)
        const sendData = {
            customerField: addCustomerFieldInput.current.value
        }
        callDxertApi(ADD_ASSET_CUSTOMER_FIELD, sendData, (data) => {
            if (data.success) {
                callDxertApi(GET_USER_INFO, {},
                    (data) => {
                        homeDispatch({type: UPDATE_USER_INFO, userInfo: data.user})
                        addCustomerFieldInput.current.value = ''
                        setDisableAddCategory(false)
                })
            }
        })
    }

    const generateListByCategory = (portfolio) => {
        const result = []
        const categorized = []
        const unCategorized = []

        for (const asset of portfolio) {
            const cats = asset.categories
            if (!cats || cats.length === 0) {
                unCategorized.push(asset)
            } else {
                for (const cat of cats) {
                    if (categorized[cat] == null) {
                        categorized[cat] = []
                    }
                    categorized[cat].push(asset)
                }
            }
        }

        if (unCategorized.length > 0) {
            result.push(
                <div className='portfolio_asset_category_group' key='unCategorizedGroup'>
                    <div className='portfolio_asset_category_group_list'>
                    {
                        generateProtfolioListGroup(unCategorized)
                    }
                    </div>
                </div>
            )
        }


        const categorizedAssets = Object.entries(categorized)
        if (categorizedAssets.length > 0) {
            for ( const [cat, assets] of categorizedAssets) {
                result.push(
                    <div key={'portfolioListCategoryGroup-' + cat} className='portfolio_asset_category_group'>
                        <div className='portfolio_asset_category_group_name'>{cat}</div>
                        <div className='portfolio_asset_category_group_list'>
                            {
                                generateProtfolioListGroup(assets, cat)
                            }
                        </div>
                    </div>
                )
            }
        }

        return result
    }

    const generateDisplayCustomerFields = () => {
        const customerFields = homeContext.userInfo.customerFields
        const userProfile = homeContext.userInfo.profile
        let hiddenAssetCustomerFields = userProfile.hiddenAssetCustomerFields
        const hiddenFields = []

        const changeHiddenAssetCustomerFields = (event) => {
            if (event.target.checked) {
                const i = hiddenFields.indexOf(event.target.name)
                if (i > -1) {
                    hiddenFields.splice(i, 1)
                }
            } else {
                hiddenFields.push(event.target.name)
            }
        }

        const saveHiddenFieldsButtonClick = () => {
            const newProfile = {...userProfile}
            newProfile.hiddenAssetCustomerFields = hiddenFields

            const sendData= {
                profile: newProfile
            }

            callDxertApi(UPDATE_PROFILE, sendData, (date) => {
                homeDispatch({type: UPDATE_USER_PROFILE, profile: newProfile})
            })
        }

        if (customerFields && customerFields.length > 0) {
            return (
                <>
                {
                    customerFields.map((value, index) => {
                        let checked = true
                        if (hiddenAssetCustomerFields && hiddenAssetCustomerFields.includes(value)) {
                            hiddenFields.push(value)
                            checked = false
                        }
                        return <div key={'PortfoioEditCustomerFieldsArea-' + index}><input type='checkbox' name={value || ''} defaultChecked={checked || false} onChange={changeHiddenAssetCustomerFields} /> {value}</div>
                    })
                }
                <div><button onClick={saveHiddenFieldsButtonClick}>Save</button></div>
                </>
            )
        } 
        return <></>
    }

    return (
        <>
            <div className='edit_asset_category'>
                <button className='edit_asset_category_button' onClick={() => {setShowEditCategory(!showEditCategory)}} ></button>
                {
                    showEditCategory &&
                    <div className='edit_asset_category_area'>
                        <div className='search_bar'>
                            <input ref={addCategoryInput} type='text' placeholder='Add new category..'/>
                            <button className='add_button' onClick={addCategoryButtonClick} disabled={disableAddCategory}></button>
                        </div>
                        <div className='edit_asset_category_current_category'>
                            Current Categories:
                            {
                                homeContext.userInfo.categories &&
                                homeContext.userInfo.categories.map((value, index) => {
                                    return <div key={'userAssetCategories' + index} className='asset_category_tag'>{value}</div>
                                })
                            }
                        </div>
                        <div className='search_bar'>
                            <input ref={addCustomerFieldInput} type='text' placeholder='Add new CustomerField..'/>
                            <button className='add_button' onClick={addCustomerFieldButtonClick} disabled={disableAddCategory}></button>
                        </div>
                        <div className='edit_asset_category_current_category'>
                            Display fields:
                            {
                                generateDisplayCustomerFields()
                            }
                        </div>
                    </div>
                }
            </div>
            {
            homeContext.portfolio &&
            generateListByCategory(homeContext.portfolio)
            }
        </>
    )
}

export default PortfolioDashboardList