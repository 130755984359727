import React, { useEffect, useState, createContext } from 'react'
import queryString from 'query-string'
import SignUpForm from '../components/SignUpForm'
import { callDxertApi } from '../utils/DxertApiUtil'
import { VERIFY_EMAIL } from '../utils/DxertApiType'

export const SignUpContext = createContext()

function SignUp(props) {
    const [showForm, setShowForm] = useState(false)
    const [userEmail, setUserEmail] = useState('')

    const email = props.location && props.location.state ? props.location.state.email : null
    const code = props.location && props.location.state ? props.location.state.code : queryString.parse(props.location.search).code
    const from = queryString.parse(props.location.search).from

    useEffect(() => {
        if (email && code) {
            const sendData = {
                email: email,
                code: code
            }
            callDxertApi(VERIFY_EMAIL, sendData,
                (data) => {
                    setShowForm(true)
                    setUserEmail(data.email)
                }
            )
        } else if (from && code) {
            const sendData = {
                from: from,
                code: code
            }
            callDxertApi(VERIFY_EMAIL, sendData,
                (data) => {
                    setShowForm(true)
                    setUserEmail(data.email)
                }
            )
        } else {
            alert('Wrong parameters')
        }
    }, [email, code, from]);

    return (
        <div>
            {
                showForm && 
                <div>
                    <SignUpContext.Provider value={{
                        email: userEmail,
                    }}>
                        <SignUpForm />
                    </SignUpContext.Provider>
                </div>
            }
        </div>
    )
}

export default SignUp