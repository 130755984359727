import React, { useContext, useEffect } from 'react'
import { signOut } from '../utils/CognitoUtil'
import { AppContext } from '../App'
import { USER_SIGNED_OUT } from '../contexts/AppActionType'

function SignOut() {
    const { appDispatch } = useContext(AppContext)

    useEffect(()=>{
        appDispatch({type:USER_SIGNED_OUT})
    })

    signOut()

    return <></>
}

export default SignOut