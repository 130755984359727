import React from 'react'
import SignInForm from '../components/SignInForm'

function SignIn() {
    return (
        <>
            <SignInForm />
        </>
    )
}

export default SignIn