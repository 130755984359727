import React, { useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { callDxertApi } from '../utils/DxertApiUtil'
import { REGISTER_EMAIL } from '../utils/DxertApiType'

function RegisterEmail() {
    const [showCodeInput, setShowCodeInput] = useState(false)
    const [goSignUp, setGoSignUp] = useState(false)

    const emailInput = useRef(null)
    const codeInput = useRef(null)

    const registerButtonClick = () => {
        const sendData = {
            email: emailInput.current.value
        }

        callDxertApi(REGISTER_EMAIL, sendData,
            (data) => {
                setShowCodeInput(true)
            }
        )
    }
    
    const verifyCode = () => {
        setGoSignUp(true)
    }
    
    return (
        <div>
            <input ref={emailInput} type='text' />
            <button onClick={registerButtonClick}>Register with email</button>
            {
                showCodeInput &&
                <div>
                    <input ref={codeInput} type='text' />
                    <button onClick={verifyCode}>Verify Code</button>
                </div>
            }
            {
                goSignUp &&
                <Redirect
                    to={{
                        pathname: "/signup/",
                        state: { 
                            email: emailInput.current.value,
                            code: codeInput.current.value
                        }
                    }}
                />
            }
        </div>
    )
}

export default RegisterEmail