import React, { useRef, useState } from 'react'
import queryString from 'query-string'
import { callDxertApi } from '../utils/DxertApiUtil'
import { SEARCH_ASSET, ADD_JOURNAL } from '../utils/DxertApiType'
import { Redirect } from 'react-router-dom'

function NewJournal(props) {
    
    const tickerParameter = queryString.parse(props.location.search).ticker

    const [ticker, setTicker] = useState(tickerParameter)
    const tickerInput = useRef(null)
    const [assets, setAssets] = useState([])
    const [disableSearchButton, setDisableSearchButton] = useState(false)
    const addJournalForm = useRef(null)
    const [journalId, setJournalId] = useState(null)

    const searchButtonClick = () => {
        setDisableSearchButton(true)
        const sendDate = {
            keyword: tickerInput.current.value,
        }
        callDxertApi(SEARCH_ASSET, sendDate, (data) => {
            setAssets(data.assets)
            setDisableSearchButton(false)
        })
    }

    const submitButtonClick = () => {
        const journal = {
            title: addJournalForm.current.title.value,
            content: addJournalForm.current.content.value
        }
        if (ticker) {
            journal.ticker = ticker
        }

        callDxertApi(ADD_JOURNAL, {journal: journal}, (data) => {
            setJournalId(data.journalId.id)
        })
    }

    return (
        <>
            {
                journalId &&
                <Redirect
                    to={{
                        pathname: '/journal/' + journalId,
                    }}
                />
            }       
            <div className='add_journal_wrapper'>
            {
                ticker ?
                <>
                    <div>Ticker: {ticker}</div>
                    <div>Change ticker:</div>
                </> :
                <div>Attach a ticker:</div>
            }
                <div className='search_bar'>
                    <input ref={tickerInput} type='text' placeholder='Search..'/>
                    <button className='search_button' onClick={searchButtonClick} disabled={disableSearchButton}></button>
                </div>
                <div className='portfolio_dashboard_header_search_result'>
                {
                    assets.map((asset) => {
                        return (
                        <div key='asset.id.id' className='portfolio_dashboard_header_search_result_item'>
                            <div>Ticker: {asset.ticker} | Name: {asset.name}</div>
                            {
                                <button className='add_button' onClick={() => (setTicker(asset.ticker))}></button>
                            }
                        </div>
                        )
                    })
                }
                </div>
                <form ref={addJournalForm}>
                    <div>
                        Title: <input className='jounal_title_input' type='text' name='title'/>
                    </div>
                    <div>
                        <textarea className='jounal_content_input' name='content' rows='30'/>
                    </div>
                </form>
                <button onClick={submitButtonClick}>Submit</button>
            </div>
        </>
    )
}

export default NewJournal