import React from 'react';
import RegisterEmail from '../components/RegisterEmail'

function Register() {
    return (
        <div>
            <RegisterEmail />
        </div>
    )
}

export default Register