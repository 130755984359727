import { UPDATE_USER_ASSET, UPDATE_USER_INFO, UPDATE_USER_ACTIONS, UPDATE_USER_JOURNALS } from './AssetActionType'

export const DefaultAssetContext = {} 

export const AssetReducer = (state, action) => {
    switch(action.type) {
        case UPDATE_USER_ASSET:
            return updateUserAsset(state, action)
        case UPDATE_USER_INFO:
            return updateUserInfo(state, action)
        case UPDATE_USER_ACTIONS:
            return updateUserActions(state, action)
        case UPDATE_USER_JOURNALS:
            return updateUserJournals(state, action)            
        default:
            return state
    }
}

function updateUserAsset(state, action) {
    let newState = {...state}
    newState.asset = action.asset
    return newState
}

function updateUserInfo(state, action) {
    let newState = {...state}
    newState.userInfo = action.userInfo
    return newState
}

function updateUserActions(state, action) {
    let newState = {...state}
    newState.actions = action.actions
    return newState
}

function updateUserJournals(state, action) {
    let newState = {...state}
    newState.journals = action.journals
    return newState
}