import React, { useState, useEffect } from 'react'
import { callDxertApi } from '../utils/DxertApiUtil'
import { GET_JOURNAL } from '../utils/DxertApiType'
import { Link } from 'react-router-dom'


function JournalDashboard() {
    const [journals, setJournals] = useState([])

    useEffect(()=>{
        const sendData = {
            tickers: [],
            journalIds: []
        }
        callDxertApi(GET_JOURNAL, sendData, (data) => {setJournals(data.journals)})
    },[])

    const generateJournalList = () => {
        const result = []
        journals.map((journal) => {
            result.push(
                <div key={journal.id.id} className='journal_dashboard_list_item'>
                    <Link to={'/journal/' + journal.id.id}>{journal.title}</Link>
                </div>
            )
            return <></>
        })
        return result
    }

    return (
        <>
            <div className='journal_dashboard_header'>
                <Link to='/newJournal/'>
                    <button className='add_button'></button>  
                </Link>
                <Link to='/journals/'>
                    <button className='readmore_button'></button>  
                </Link>
            </div>
            <div className='journal_dashboard_list'>
                {
                    journals.length > 0 &&
                    generateJournalList()
                }
            </div>
        </>
    )
}

export default JournalDashboard