import React, { useContext, useRef, useState } from 'react'
import { UPDATE_USER_ASSET } from '../contexts/AssetActionType'
import { AssetContext } from '../pages/Asset'
import { ADD_ASSET_TAG, UPDATE_ASSET } from '../utils/DxertApiType'
import { callDxertApi } from '../utils/DxertApiUtil'

function AssetDashboardDetail() {
    const { assetContext, assetDispatch }  = useContext(AssetContext)
    const asset = assetContext.asset
    const userInfo = assetContext.userInfo
    const addTagInput = useRef(null)
    const addFieldName = useRef(null);
    const addFieldInput = useRef(null)
    const [disableClick, setDisableClick] = useState(false)
    const [showAddField, setShowAddField] = useState(false)
    const catsCheck = []
  

    const addTagButtonClick = () => {
        setDisableClick(true)
        const tag = addTagInput.current.value
        const sendData = {
            tag: tag
        }
        callDxertApi(ADD_ASSET_TAG, sendData, (data) => {
            if (!asset.tags) {
                asset.tags = []
            }
            asset.tags.push(tag)
            callDxertApi(UPDATE_ASSET, { userAsset: asset },
                (data) => {
                    if (data.success) {
                        assetDispatch({type: UPDATE_USER_ASSET, asset: asset})
                    }
                    addTagInput.current.value = ''
                    setDisableClick(false)
            })
        })
    }

    const manageCategoryButtonClick = () => {
        setDisableClick(true)
        asset.categories = []
        for (const cat of catsCheck) {
            asset.categories.push(cat)
        }
        callDxertApi(UPDATE_ASSET, { userAsset: asset },
            (data) => {
                if (data.success) {
                    assetDispatch({type: UPDATE_USER_ASSET, asset: asset})
                }
                setDisableClick(false)
        })

    }

    const handleCategorychecked = (event) => {
        if (event.target.checked) {
            catsCheck.push(event.target.name)
        } else {
            const i = catsCheck.indexOf(event.target.name)
            if (i > -1) {
                catsCheck.splice(i, 1)
            }
        }
    }

    const generateCategoryArea = () => {
        if (asset && userInfo) {
            if (userInfo.categories) {
                const result = []
                userInfo.categories.map((value, index) => {
                    const checked = asset.categories && asset.categories.includes(value)
                    if (checked) {
                        catsCheck.push(value)
                    }
                    result.push(<div key={'userAssetCategory' + index}><input name={value || ''} type='checkbox' defaultChecked={checked || false} onChange={handleCategorychecked} disabled={disableClick} /> {value} </div>)
                    return <></>
                })
    
                if (result.length > 0) {
                    result.push(<button key='manageAssetCategory' onClick={manageCategoryButtonClick} disabled={disableClick}>Submit</button>)
                    return result
                }
            }
        }
        return <></>
    }

    const generateCustomerFieldsArea = () => {
        if (asset && userInfo) {
            if (userInfo.customerFields) {
                const result = []
                if (asset.customerFields) {
                    asset.customerFields.map((customerField, index) => {
                        result.push(
                            <div key={'customerField-' + index} className='asset_customer_field'>
                                <div>{customerField.name}</div>
                                <div>{customerField.value}</div>
                            </div>
                        )
                        return <></>
                    })
                }
                if (userInfo.customerFields.length > 0) {
                    result.push(<button key='addCustomerFieldButton' className='edit_button' onClick={() => {setShowAddField(!showAddField)}} disabled={disableClick}></button>)
                    result.push(
                        showAddField &&
                        <div key='addCustomerFieldFrom' className='asset_add_customer_field'>
                            <select ref={addFieldName}>
                            {
                                userInfo.customerFields.map((value, index) => {
                                    return (<option key={'userCustomerField-' + index} value={value}>{value}</option>)
                                })
                            }
                            </select>
                            <input ref={addFieldInput} type='text' />
                            <button className='confirm_button' onClick={confirmAddCustomerFieldClick} disabled={disableClick}></button>
                        </div>
                    )
                    return result
                }
            }
        }
    }

    const confirmAddCustomerFieldClick = () => {
        const fieldName = addFieldName.current.value
        const fieldValue = addFieldInput.current.value
        if (!asset.customerFields) {
            asset.customerFields = []
        }

        let updated = false
        let removeIndex = null
        for (const index in asset.customerFields) {
            const customerField = asset.customerFields[index]
            if (customerField.name === fieldName) {
                if (fieldValue) {
                    customerField.value = fieldValue
                    updated = true
                } else {
                    removeIndex = index
                    updated = true
                }
                break
            }
        }

        if (removeIndex !== null) {
            asset.customerFields.splice(removeIndex, 1)
        }
        
        if (!updated) {
            asset.customerFields.push(
                {
                    name: fieldName,
                    value: fieldValue
                }
            )
        }

        callDxertApi(UPDATE_ASSET, { userAsset: asset },
            (data) => {
                if (data.success) {
                    assetDispatch({type: UPDATE_USER_ASSET, asset: asset})
                }
                addFieldInput.current.value = ''
                setDisableClick(false)
        })
    }

    return (
        <>
        {
            asset &&
            <div className='asset_dashboard_detail_content'>
                <div className='asset_dashboard_detail_content_info'>
                    <div>Ticker: {asset.ticker}</div>
                    <div>Status: {asset.status}</div>
                    <div>Position: {asset.position ? asset.position : 'N/A'}</div>
                </div>
                <div className='asset_dashboard_detail_tags'>
                    Tags:
                    <div className='search_bar'>
                        <input ref={addTagInput} type='text' placeholder='Add new tag..'/>
                        <button className='add_button' onClick={addTagButtonClick} disabled={disableClick}></button>
                    </div>
                    <div className='edit_asset_category_current_category'>
                        Current Tags:
                        {
                            asset.tags &&
                            asset.tags.map((value, index) => {
                                return <div key={'userAssetTag-' + index} className='asset_category_tag'>{value}</div>
                            })
                        }
                    </div>
                </div>
                <div className='asset_dashboard_detail_cats'>
                    Categories:
                {
                    generateCategoryArea()
                }
                </div>
                <div className='asset_dashboard_detail_fileds'>
                    Customer fields:
                {
                    generateCustomerFieldsArea()
                }
                </div>
            </div>

        }
        </>
    )
}

export default AssetDashboardDetail