import React from 'react'
import PortfolioDashboardFilter from './PortfolioDashboardFilter'
import PortfolioDashboardSearch from './PortfolioDashboardSearch'
import PortfolioDashboardSummary from './PortfolioDashboardSummary'
import PortfolioDashboardList from './PortfolioDashboardList'


function PortfolioDashboard() {
    return (
        <>
            <div className='portfolio_dashboard_header'>
                <div className='portfolio_dashboard_header_asset'>
                    <div className='portfolio_dashboard_header_search'>
                        <PortfolioDashboardSearch />
                    </div>
                    <div className='portfolio_dashboard_header_filter'>
                        <PortfolioDashboardFilter />
                    </div>
                </div>
                <div className='portfolio_dashboard_portfolio_summary'>
                    <PortfolioDashboardSummary />
                </div>
            </div>
            <div className='portfolio_dashboard_list'>
                <PortfolioDashboardList />
            </div>
        </>
    )
}

export default PortfolioDashboard