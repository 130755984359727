import React, { createContext, useReducer } from 'react'
import './css/style.css'
import { BrowserRouter as Router } from "react-router-dom"
import Routes from './Routes'
import Header from './components/Header'
import { DefaultAppContext, AppReducer } from './contexts/AppContext'

export const AppContext = createContext()

function App() {
    const [appContext, appDispatch] = useReducer(AppReducer, DefaultAppContext)

    return (
        <AppContext.Provider value={{appContext, appDispatch}}>
            <Router>
                <Header />
                <Routes />
            </Router>
        </AppContext.Provider>
    )
}

export default App