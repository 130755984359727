import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../App'

function Header() {
    const { appContext }  = useContext(AppContext)
    return (
        <div className='header'>
            <div className='header_container'>
                <div>
                    <div><Link to="/">LOGO</Link></div>
                </div>
                <div className='menu'>
                    {
                        appContext.signedIn ?
                        <>
                        <div><Link to='/account/'>Account</Link></div>
                        <div><Link to='/signout/'>Sign out</Link></div>
                        </>
                        :
                        <>
                        <div><Link to='/signin/'>Sign in</Link></div>
                        <div><Link to='/register/'>Register</Link></div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header