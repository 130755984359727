import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Welcome from './pages/Welcome'
import Home from './pages/Home'
import Register from './pages/Register'
import SignUp from './pages/SignUp'
import SignIn from './pages/SignIn'
import SignOut from './pages/SignOut'
import Account from './pages/Account'
import Asset from './pages/Asset'
import NotFound from './pages/NotFound'
import Journals from './pages/Journals'
import Journal from './pages/Journal'
import NewJournal from './pages/NewJournal'
import { AppContext } from './App'

function Routes() {

    const { appContext } = useContext(AppContext)
    
    // Show the component only when the user is signed in
    // Otherwise, redirect the user to index page
    const PrivateRoute = ({component: Component, ...rest}) => {
        return (
            <Route {...rest} render={props => (
                appContext.signedIn ?
                    <Component {...props} />
                : <Redirect to="/" />
            )} />
        );
    };

    // restricted = false meaning public route - anyone can access
    // restricted = true meaning restricted route - only anonymous user can access, route to home otherwise
    const PublicRoute = ({component: Component, restricted, ...rest}) => {
        return (
            <Route {...rest} render={props => (
                appContext.signedIn && restricted ?
                    <Redirect to="/home/" />
                : <Component {...props} />
            )} />
        );
    };

    return (
        <div className='container'>
            <Switch>
                <PublicRoute restricted={true} path='/' component={Welcome} exact />
                <PublicRoute restricted={true} path='/register/' component={Register} />
                <PublicRoute restricted={true} path='/signup/' component={SignUp} />
                <PublicRoute restricted={true} path='/signin/' component={SignIn} />
                <PrivateRoute path='/account/' component={Account} />
                <PrivateRoute path='/signout/' component={SignOut} />
                <PrivateRoute path='/home/' component={Home} />
                <PrivateRoute path='/asset/:ticker' component={Asset} />
                <PrivateRoute path='/journal/:journalId' component={Journal} />
                <PrivateRoute path='/journals/' component={Journals} />
                <PrivateRoute path='/newJournal/' component={NewJournal} />
                <Route component={NotFound} />
            </Switch>
        </div>
    )
}



export default Routes