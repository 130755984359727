import React from 'react'

function NotFound() {
    return (
        <div className='account_container'>
            PAGE NOT FOUND
        </div>
    )
}

export default NotFound