import React, { useContext } from 'react'
import { HomeContext } from '../pages/Home'
import { UPDATE_PORTOLIO_FILTER } from '../contexts/HomeActionType'

function PortfolioDashboardFilter() {
    const { homeContext, homeDispatch }  = useContext(HomeContext)

    const filterTagClick = (event, tag) => {
        let portfolioFilter = homeContext.portfolioFilter
        if (event.target.className === 'dashboard_tags_filter_tag') {
            event.target.className = 'dashboard_tags_filter_tag_active'
            if (!homeContext.portfolioFilter) {
                portfolioFilter = []
            }
            portfolioFilter.push(tag) 
            homeDispatch({type: UPDATE_PORTOLIO_FILTER, portfolioFilter: portfolioFilter})
        } else {
            event.target.className = 'dashboard_tags_filter_tag'
            if (portfolioFilter) {
                const i = portfolioFilter.indexOf(tag);
                portfolioFilter.splice(i, 1);
                homeDispatch({type: UPDATE_PORTOLIO_FILTER, portfolioFilter: portfolioFilter})
            }
        }
    }

    return (
        <div className='dashboard_tags_filter'>
            Filter by Tags:
        {
            homeContext.userInfo &&
            homeContext.userInfo.tags &&
            homeContext.userInfo.tags.map((tag, index) => {
                return (
                    <div key={'tageFilter-' + index} className='dashboard_tags_filter_tag' onClick={(event) => {filterTagClick(event, tag)}}>
                        {tag}
                    </div>
                )
            })
        }
        </div>
    )
}

export default PortfolioDashboardFilter