import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoRefreshToken } from 'amazon-cognito-identity-js'
import { decode } from 'jsonwebtoken'
import Cookies from 'universal-cookie'

const UserPoolId = 'us-east-1_gs0oL7juj'
const ClientId = '1iqkfnho8sf3t38c2ree6b9m0e'

export const signUpUser = (signUpData, callback) => {
    const userPool = new CognitoUserPool({
        UserPoolId: UserPoolId,
        ClientId: ClientId,
    })

    const attributeEmail = new CognitoUserAttribute({
        Name: 'email',
        Value: signUpData.email,
    })

    const attributeFirstName = new CognitoUserAttribute({
        Name: 'custom:firstName',
        Value: signUpData.firstName,
    })

    const attributeLastName = new CognitoUserAttribute({
        Name: 'custom:lastName',
        Value: signUpData.lastName,
    })

    const attributeJobTitle = new CognitoUserAttribute({
        Name: 'custom:jobTitle',
        Value: signUpData.jobTitle,
    })

    const attributeList = [attributeEmail, attributeFirstName, attributeLastName, attributeJobTitle]

    userPool.signUp(signUpData.email, signUpData.password, attributeList, null, callback);
}

export const authUser = (authData, callbacks) => {
    const authenticationDetails = new AuthenticationDetails(
        authData
    )

    const userPool = new CognitoUserPool({
        UserPoolId: UserPoolId,
        ClientId: ClientId,
    })

    const user = new CognitoUser({
        Username: authData.Username,
        Pool: userPool,
    })

    user.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
            const cookies = new Cookies()
            cookies.set('cognitoIdToken', result.getIdToken().getJwtToken(), {path: '/'})
            cookies.set('cognitoAccessToken', result.getAccessToken().getJwtToken(), {path: '/'})
            user.getSession((err, session) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }
                cookies.set('cognitoRefreshToken', session.getRefreshToken(), {path: '/'})
            })

            callbacks.onSuccess(result)
        },

        onFailure: (err) => {
            callbacks.onFailure(err)
        }
    })
}

export const getIdToken = () => {
    return new Cookies().get('cognitoIdToken')
}

export const getAccessToken = () => {
    return new Cookies().get('cognitoAccessToken')
}

export const getRefreshToken = () => {
    return new Cookies().get('cognitoRefreshToken')
}

export const isSignIn = () => {
    return getIdToken()
}

export const signOut = () => {
    const cookies = new Cookies()

    const idToken = cookies.get('cognitoIdToken')
    if (!idToken) {
        return false
    }

    const { email } = decode(idToken)

    const userPool = new CognitoUserPool({
        UserPoolId: UserPoolId,
        ClientId: ClientId,
    })

    const user = new CognitoUser({
        Username: email,
        Pool: userPool,
    })

    user.signOut()

    cookies.remove('cognitoRefreshToken', {path: '/'})
    cookies.remove('cognitoAccessToken', {path: '/'})
    cookies.remove('cognitoIdToken', {path: '/'})
}

export const getJwtToken = () => {
    return new Promise(
        (resolve) => {
            const token = getIdToken()
            const refreshToken = getRefreshToken()
       
            if (!token || !refreshToken) {
                return resolve(null)
            }
       
            const { exp, email } = decode(token)
            if (Date.now() < exp * 1000) { // Token still valid
                return resolve(token)
            }
            
            const userPool = new CognitoUserPool({
                UserPoolId: UserPoolId,
                ClientId: ClientId,
            })
            const user = new CognitoUser({
                Username: email,
                Pool: userPool,
            })

            // Refresh token
            user.refreshSession(new CognitoRefreshToken(refreshToken), (err, session) => {
                if (err) {
                    return resolve(null)
                } else {
                    const cookies = new Cookies()
                    cookies.set('cognitoIdToken', session.getIdToken().getJwtToken(), {path: '/'})
                    cookies.set('cognitoAccessToken', session.getAccessToken().getJwtToken(), {path: '/'})
                    return resolve()
                }
            })
         }
     )
}
