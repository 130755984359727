import React, { useRef, useContext } from 'react'
import { authUser } from '../utils/CognitoUtil'
import { AppContext } from '../App'
import { USER_SIGNED_IN } from '../contexts/AppActionType'

function SignInForm() {
    const { appDispatch } = useContext(AppContext)

    const emailInput = useRef(null)
    const passwordInput = useRef(null)

    const signInButtonClick = () => {
        const authData = {
            Username: emailInput.current.value,
            Password: passwordInput.current.value
        }
        authUser(authData, signInCallbacks)
    }

    const signInCallbacks = {
        onSuccess: (result) => {
            console.log(result)
            appDispatch({type:USER_SIGNED_IN})
            
        },

        onFailure: (err) => {
            alert(err.message || JSON.stringify(err))
        }
    }

    return (
        <div>
            Email: <input ref={emailInput} type='text' />
            Password:  <input ref={passwordInput} type='password' />
            <button onClick={signInButtonClick}>Sign In</button>
        </div>
    )
}

export default SignInForm