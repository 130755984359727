import React, { useState, useEffect, useRef } from 'react'
import { callDxertApi } from '../utils/DxertApiUtil'
import { GET_USER_INFO, UPDATE_PROFILE } from '../utils/DxertApiType'

function Account() {
    const [userInfo, setUserInfo] = useState({})
    const [showEdit, setShowEdit] = useState(false)
    const [disableClick, setDisableClick] = useState(false)
    const updateForm = useRef(null)

    useEffect(()=>{callDxertApi(GET_USER_INFO, {}, (data) => {setUserInfo(data.user)})},
        [])

    const updateProfile = () => {
        setDisableClick(true)
        const profile = userInfo.profile
        profile.firstName = updateForm.current.updateFirstName.value
        profile.lastName = updateForm.current.updateLastName.value
        profile.jobTitle = updateForm.current.updateJob.value
        const sendData= {
            profile: profile
        }
        callDxertApi(UPDATE_PROFILE, sendData, (data) => {
            callDxertApi(GET_USER_INFO, {}, (data) => {
                setUserInfo(data.user)
                setDisableClick(false)
            })
        })
    }

    return (
        <div className='account_container'>
        {
            userInfo.profile &&
            <div className='user_profile'>
                <div>Email: {userInfo.profile.email}</div>
                <div>FirstName: {userInfo.profile.firstName}</div>
                <div>LastName: {userInfo.profile.lastName}</div>
                <div>Job: {userInfo.profile.jobTitle}</div>
            </div>
        }
        <button className='edit_button' onClick={() => {setShowEdit(!showEdit)}}></button>
        {
            showEdit &&
            <div>
                <form ref={updateForm}>
                    <div>FirstName: <input type='text' name='updateFirstName' defaultValue={userInfo.profile.firstName} /></div>
                    <div>LastName: <input type='text' name='updateLastName' defaultValue={userInfo.profile.lastName} /></div>
                    <div>Job: <input type='text' name='updateJob' defaultValue={userInfo.profile.jobTitle} /></div>
                </form>
                <div><button onClick={updateProfile} disabled={disableClick}>Submit</button></div>
            </div>
        }
        </div>
    )
}

export default Account