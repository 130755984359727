import React, { useState, useMemo, useContext } from 'react'
import { callDxertApi } from '../utils/DxertApiUtil'
import { GET_JOURNAL } from '../utils/DxertApiType'
import { Link } from 'react-router-dom'
import { AssetContext } from '../pages/Asset'

function AssetDashboardJournal() {
    const { assetContext }  = useContext(AssetContext)
    const asset = assetContext.asset
    
    const [journals, setJournals] = useState([])

    const fetchJournals = useMemo(() => {
        const sendData = {
            tickers: [asset.ticker],
            journalIds: []
        }
        callDxertApi(GET_JOURNAL, sendData, (data) => {setJournals(data.journals)})
    }, [asset.ticker])


    const generateJournalList = () => {
        const result = []
        journals.map((journal) => {
            result.push(
                <div key={journal.id.id} className='journal_dashboard_list_item'>
                    <Link to={'/journal/' + journal.id.id}>{journal.title}</Link>
                </div>
            )
            return <></>
        })
        return result
    }

    return (
        <>
        {
            asset && 
            fetchJournals
        }
        {
            journals &&
            <>
                <div className='asset_journal_board_header'>
                    <Link to={'/newJournal/?ticker=' + asset.ticker}>
                        <button className='add_button'></button>
                    </Link>
                </div>
                <div>
                    {
                        journals.length > 0 &&
                        generateJournalList()
                    }
                </div>
            </>
        }
        </>
    )
}

export default AssetDashboardJournal