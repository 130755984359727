export const REGISTER_EMAIL = {
    url: 'https://api.dxert.com/registerEmail',
    needAuth: false
}

export const VERIFY_EMAIL = {
    url: 'https://api.dxert.com/verifyEmail',
    needAuth: false
}

export const GET_USER_INFO = {
    url: 'https://api.dxert.com/getUserInfo',
    needAuth: true
}

export const SEARCH_ASSET = {
    url: 'https://api.dxert.com/searchAsset',
    needAuth: true
}

export const GET_PORTFOLIO = {
    url: 'https://api.dxert.com/getPortfolio',
    needAuth: true
}

export const UPDATE_PORTFOLIO = {
    url: 'https://api.dxert.com/updatePortfolio',
    needAuth: true
}

export const ADD_ASSET_CATEGORY = {
    url: 'https://api.dxert.com/addAssetCategory',
    needAuth: true
}

export const ADD_ASSET_TAG = {
    url: 'https://api.dxert.com/addAssetTag',
    needAuth: true
}

export const ADD_ASSET_CUSTOMER_FIELD = {
    url: 'https://api.dxert.com/addAssetCustomerField',
    needAuth: true
}

export const UPDATE_ASSET = {
    url: 'https://api.dxert.com/updateAsset',
    needAuth: true
}

export const GET_ACTION = {
    url: 'https://api.dxert.com/getAction',
    needAuth: true
}

export const ADD_ACTION = {
    url: 'https://api.dxert.com/addAction',
    needAuth: true
}

export const GET_JOURNAL = {
    url: 'https://api.dxert.com/getJournal',
    needAuth: true
}

export const ADD_JOURNAL = {
    url: 'https://api.dxert.com/addJournal',
    needAuth: true
}

export const UPDATE_PROFILE = {
    url: 'https://api.dxert.com/updateProfile',
    needAuth: true
}

export const UPDATE_JOURNAL = {
    url: 'https://api.dxert.com/updateJournal',
    needAuth: true
}