import { USER_SIGNED_IN, USER_SIGNED_OUT } from './AppActionType'
import { isSignIn } from '../utils/CognitoUtil'

export const DefaultAppContext = {
    signedIn:isSignIn()
} 

export const AppReducer = (state, action) => {
    switch(action.type) {
        case USER_SIGNED_IN:
            return userSignIn(state, action)
        case USER_SIGNED_OUT:
            return userSignOut(state, action)
        default:
            return state
    }
}

function userSignIn(state, action) {
    let newState = {...state}
    newState.signedIn = true;
    return newState
}

function userSignOut(state, action) {
    let newState = {...state}
    newState.signedIn = false
    return newState
}