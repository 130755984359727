import React, { useContext, useRef } from 'react'
import { signUpUser, authUser } from '../utils/CognitoUtil'
import { SignUpContext } from '../pages/SignUp'
import { AppContext } from '../App'
import { USER_SIGNED_IN } from '../contexts/AppActionType'

function SignUpForm() {
    const { appDispatch } = useContext(AppContext)
    const signUpContext = useContext(SignUpContext)

    const firstNameInput = useRef(null)
    const lastNameInput = useRef(null)
    const jobTitleInput = useRef(null)
    const passwordInput = useRef(null)

    const signUpButtonClick = () => {
        const password = passwordInput.current.value

        const signUpData = {
            email: signUpContext.email,
            password: password,
            firstName: firstNameInput.current.value,
            lastName: lastNameInput.current.value,
            jobTitle: jobTitleInput.current.value
        }

        signUpUser(signUpData, (err, result) => {
            if (err) {
                alert(err.message || JSON.stringify(err))
            } else {
                const authData = {
                    Username: result.user.username,
                    Password: password
                }
                authUser(authData, signInCallbacks)
            }
        })
    }
    
    const signInCallbacks = {
        onSuccess: (result) => {
            appDispatch({type:USER_SIGNED_IN})
        },

        onFailure: (err) => {
            alert(err.message || JSON.stringify(err))
        }
    }

    return (
        <div>
            First Name: <input ref={firstNameInput} type='text' />
            Last Name: <input ref={lastNameInput} type='text' />
            Job Title: <input ref={jobTitleInput} type='text' />
            Create a password: <input ref={passwordInput} type='password' />
            <button onClick={signUpButtonClick}>Sign Up</button>
        </div>
    )
}

export default SignUpForm